import React, { useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import contactSchema from "../schema/contactSchema";
import Error from "../components/Error";

const FormInput = ({
	id,
	label,
	type,
	placeholder,
	value,
	onChange,
	onBlur,
	error,
	touched,
	isRequired,
}) => (
	<div className="grid gap-1.5 w-full">
		<label
			htmlFor={id}
			className={`block text-sm font-medium text-gray-700 ${
				isRequired
					? "after:content-['*'] after:ml-0.5 after:text-red-500"
					: ""
			}`}
		>
			{label}
		</label>
		<input
			id={id}
			type={type}
			name={id}
			placeholder={placeholder}
			value={value}
			onChange={onChange}
			onBlur={onBlur}
			className={`w-full py-2 px-4 border rounded-md focus:outline-none focus:ring-2 ${
				error && touched
					? "border-red-500 focus:ring-red-500"
					: "border-gray-300 focus:ring-blue-500"
			}`}
		/>
		{error && touched && <Error msg={error} />}
	</div>
);

function ContactUs() {
	const location = useLocation();
	const navigate = useNavigate();
	const { pathname } = location;
	// const url = "http://localhost:4000/submit";
	const url =
		"https://ueb1qcvqjg.execute-api.ap-south-1.amazonaws.com/submit";

	// Set page title
	if (pathname === "/contact-us")
		document.title = "Contact Us - Sumcon Infraventures";

	// Loading state
	const [loading, setLoading] = useState(false);

	const initialValues = {
		name: "",
		email: "",
		phone_number: "",
		message: "",
	};

	const { values, handleBlur, handleChange, errors, handleSubmit, touched } =
		useFormik({
			initialValues,
			validationSchema: contactSchema,
			validateOnBlur: true,
			validateOnChange: true,
			onSubmit: async (values, { resetForm }) => {
				setLoading(true); // Start loading
				try {
					const response = await fetch(url, {
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify(values),
					});
					if (response.ok) {
						// Show success alert
						Swal.fire({
							title: "Success!",
							text: "Your message has been sent successfully.",
							icon: "success",
							confirmButtonText: "Go to Home",
							customClass: {
								confirmButton:
									"py-2 px-4 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition",
							},
						}).then(() => navigate("/"));
						resetForm();
					} else {
						// Show error alert
						Swal.fire({
							title: "Error!",
							text: "There was an issue submitting the form. Please try again.",
							icon: "error",
							confirmButtonText: "Okay",
							customClass: {
								confirmButton:
									"py-2 px-4 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition",
							},
						});
					}
				} catch (error) {
					Swal.fire({
						title: "Error!",
						text: "An unexpected error occurred.",
						icon: "error",
						confirmButtonText: "Okay",
						customClass: {
							confirmButton:
								"py-2 px-4 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition",
						},
					});
				} finally {
					setLoading(false); // Stop loading
				}
			},
		});

	const contactItems = [
		{
			icon: (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="24"
					height="24"
					fill="none"
					stroke="#333333"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				>
					<path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
					<path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z" />
				</svg>
			),
			label: (
				<>
					3rd Floor
					<br />
					46 B.B Ganguly Street
					<br />
					Kolkata, 700012
				</>
			),
			link: "https://www.google.com/maps/place/SUMCON+INFRAVENTURES+LLP/@22.5774401,88.3499099,15z/data=!4m6!3m5!1s0x3a0277bb1711b4f3:0x163c80b8d5a167f0!8m2!3d22.5774401!4d88.3499099!16s%2Fg%2F11fn7j77nz?entry=ttu&g_ep=EgoyMDI0MTExOS4yIKXMDSoASAFQAw%3D%3D",
		},
		{
			icon: (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="24"
					height="24"
					fill="none"
					stroke="#333333"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				>
					<path d="M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z" />
					<path d="M3 7l9 6l9 -6" />
				</svg>
			),
			label: "info@sumconinfraventures.com",
			onClick: () =>
				(window.location = "mailto:info@sumconinfraventures.com"),
		},
		{
			icon: (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="24"
					height="24"
					fill="none"
					stroke="#333333"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				>
					<path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
				</svg>
			),
			label: "(033) 4004-7620",
			link: "tel:03340047620",
		},
	];

	return (
		<section className="w-full flex justify-center py-10 px-5 lg:px-12 xl:px-16 bg-[#708090]/40">
			<div className="container">
				<div className="flex flex-col justify-around gap-5 md:gap-x-8 md:flex-row">
					{/* Form Section */}
					<div className="w-full max-w-[600px] flex-1 mx-auto p-6 bg-[#F5F5F5] rounded-md shadow-lg">
						<h2 className="text-3xl md:text-4xl font-semibold mb-4 text-[#4682B4] text-center md:text-left">
							Send us a message
						</h2>
						<form onSubmit={handleSubmit}>
							<div className="max-w-3xl mx-auto flex flex-wrap items-start gap-4 my-2">
								<FormInput
									id="name"
									label="Name"
									type="text"
									placeholder="Enter your name"
									value={values.name}
									onChange={handleChange}
									onBlur={handleBlur}
									error={errors.name}
									touched={touched.name}
									isRequired
								/>
								<FormInput
									id="email"
									label="Email"
									type="email"
									placeholder="Enter your email"
									value={values.email}
									onChange={handleChange}
									onBlur={handleBlur}
									error={errors.email}
									touched={touched.email}
									isRequired
								/>
								<FormInput
									id="phone_number"
									label="Phone Number"
									type="tel"
									placeholder="Enter your phone"
									value={values.phone_number}
									onChange={handleChange}
									onBlur={handleBlur}
									error={errors.phone_number}
									touched={touched.phone_number}
								/>
								<div className="grid gap-1.5 w-full">
									<label
										htmlFor="message"
										className="block text-sm font-medium text-[#333333] after:content-['*'] after:ml-0.5 after:text-red-500"
									>
										Message
									</label>
									<textarea
										id="message"
										className={`min-h-[150px] resize-y border-2 rounded-md outline-none text-[#333333] pl-5 py-3 ${
											errors.message && touched.message
												? "border-red-500 focus:outline-red-500"
												: "border-zink-500 focus:outline-white"
										}`}
										placeholder="Enter your message"
										value={values.message}
										onChange={handleChange}
										onBlur={handleBlur}
									/>
									{errors.message && touched.message && (
										<Error msg={errors.message} />
									)}
								</div>
								<button
									type="submit"
									className="w-full md:w-36 py-3 text-lg text-white font-medium rounded-md bg-[#4682B4]/70 hover:bg-[#4682B4] transition duration-300"
									disabled={loading}
								>
									{loading ? "Submitting..." : "Submit"}
								</button>
							</div>
						</form>
					</div>
					{/* Contact Info Section */}
					<div className="flex flex-col flex-1 gap-6">
						<iframe
							title="Sumcon Infraventures Location"
							src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14736.17787326942!2d88.3499099!3d22.5774401!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a0277bb1711b4f3%3A0x163c80b8d5a167f0!2sSUMCON%20INFRAVENTURES%20LLP!5e0!3m2!1sen!2sin!4v1732535102038!5m2!1sen!2sin"
							className="w-full h-[300px] sm:h-90 md:h-96 rounded-md border-0"
							loading="lazy"
							referrerPolicy="no-referrer-when-downgrade"
						></iframe>
						<div className="w-full flex flex-wrap justify-around gap-2">
							{contactItems.map(
								({ icon, label, link, onClick }, idx) => (
									<div
										key={idx}
										className="p-3 flex items-center flex-col gap-4"
									>
										{link ? (
											<Link
												to={link}
												target="_blank"
												className="w-16 h-16 flex justify-center items-center rounded-full border-2 border-[#333333]"
											>
												{icon}
											</Link>
										) : (
											<button
												onClick={onClick}
												className="w-16 h-16 flex justify-center items-center rounded-full border-2 border-[#333333]"
											>
												{icon}
											</button>
										)}
										<p className="text-sm text-center font-medium text-[#333333] opacity-70">
											{label}
										</p>
									</div>
								)
							)}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default ContactUs;
